import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PendientesList({pendientes}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {}, []);

    const handleGoToVenta = (numero) => {
        dispatch({type: "search/set", payload: {search: numero }});
        navigate('/ventas/1', { replace : true});
      }
 
    return (
        <div className="w-100 d-flex flex-column my-3">
            { pendientes?.map(pendiente => {
                return (
                  <div className="alert alert-warning">
                    <div className="w-100 d-flex">
                        <div className="d-flex flex-grow-1">
                            <div className="d-flex align-items-center">
                                <span className="me-2">
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#ffc107" /> 
                                </span>
                                <small className="fw-bold" onClick={() => handleGoToVenta(pendiente.presupuesto.numero)}>{pendiente.presupuesto.numero}</small>
                                <span className="mx-2">|</span>
                                <small>{moment(pendiente.fecha).format('DD-MM-YYYY')}</small>
                                { user?.responsable &&
                                    <>
                                        <span className="mx-2">|</span>
                                        <small className="fw-bold text-uppercase">{pendiente.created_by.username}</small>
                                    </>
                                }
                            </div>

                            <div className="d-flex flex-column">
                                <ul className="m-0">
                                {pendiente?.piezas?.map(pieza => {
                                    return (
                                        <li style={{listStyle: 'square'}}>
                                            <div className="d-flex">
                                                <small className="text-uppercase">{pieza.nombre}</small>

                                                <div className="d-flex align-items-center ms-3">
                                                    <small className="text-uppercase fw-bold me-2">{pieza.proveedor.nombre}</small>
                                                    <small>{pieza.proveedor.telefono}</small>
                                                    { (pieza.proveedor.telefono_recambios) &&
                                                        <>
                                                            <span className="mx-2">|</span>
                                                            <small>Recambios: {pieza.proveedor.persona_recambios} {pieza.proveedor.telefono_recambios}</small>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>
                        </div>

                        <p className="align-self-end">Pendiente de confirmación</p>
                    </div>
                  </div>
                )
            })}
        </div>
    );
}