import { useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import { deleteTransportista } from "../../../shared/services/transportistas";
import { deleteServicioEnvio } from "../../../shared/services/serviciosEnvio";
import TransportistaForm from "./components/TransportistaForm";
import ServicioEnvioForm from "./components/ServicioEnvioForm";
import ServiciosTable from "./components/ServiciosTable";
import InfoModal from "../../../shared/components/InfoModal";
import { fetchReadOnlyData } from "../../../../../../redux/readOnly";

export default function Transportistas() {
    const dispatch = useDispatch();
    const [transportistaId, setTransportistaId] = useState(null);
    const [currentTransportista, setCurrentTransportista] = useState(null);
    const [currentServicio, setCurrentServicio] = useState(null);
    const [isEditTransportista, setIsEditTransportista] = useState(false);
    const [isEditServicio, setIsEditServicio] = useState(false);
    const [isForDelete, setIsForDelete] = useState(false);
    const [openTransportistaForm, setOpenTransportistaForm] = useState(false);
    const [openServiciosList, setOpenServiciosList] = useState(false);
    const [openServiciosForm, setOpenServiciosForm] = useState(false);

    const transportistas = useSelector(state => {
        return state.transportistas
    });

    const handleOpenServicios = (transportista) => {
        setTransportistaId(transportista.id);
        setOpenServiciosList(true);
    }
    const handleCloseServicios = () => {
        setTransportistaId(null);
        setOpenServiciosList(false);
    }

    const handleOpenForm = (element, elementValue = null) => {
        if(element === 'transportista') {
            setCurrentTransportista(elementValue);

            if(elementValue) {
                setIsEditTransportista(true);
            }
            
            setOpenTransportistaForm(true);
        } else {
            const servicio = transportistas.find(t => t.id === transportistaId).servicios.find(v => v.id === elementValue);
            setCurrentServicio(servicio);

            if(elementValue) {
                setIsEditServicio(true);
            }
            
            setOpenServiciosForm(true);
        }
    }

    const handleCloseForm = () => { 
        setOpenTransportistaForm(false);
        setOpenServiciosForm(false);
        setCurrentTransportista(null);
        setCurrentServicio(null);
        setIsEditTransportista(false);
        setIsEditServicio(false);
    }

    const handleActionDone = () => {
        dispatch(fetchReadOnlyData());
        handleCloseForm();
    }

    const handleDeleteElement = async(element, elementId) => {
        let response;
        dispatch({type: 'loading/set', payload: true});

        if(element === 'transportista') {
            response = await deleteTransportista(elementId);
            setTransportistaId(null);
            setCurrentTransportista(null);
        } else {
            response = await deleteServicioEnvio(elementId);
            setCurrentServicio(null);
        }

        if(response && response.data) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchReadOnlyData());
        }
    }

    const handleDeleteTarifa = (id) => { 
        setTransportistaId(id);
        setIsForDelete(true); 
    }

    const handleConfirmDelete = (confirm) => {
        if(confirm) {
            handleDeleteElement('transportista', transportistaId)
        } else setIsForDelete(false);
    }

    return (
        <div className="w-100 d-flex justify-content-center">
            <div className="col-12 col-md-8 d-flex flex-column align-items-end">
                <button type="button" className="btn btn-add ms-0 ms-md-5" onClick={() => handleOpenForm('transportista')}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>

                <div className="w-100 d-flex flex-column mt-4">
                    { transportistas.map(transportista => {
                        return (
                            <div className="w-100 d-flex flex-column" key={'tarifa-' + transportista.id}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="m-0">{transportistaId === transportista.id ? <b>{transportista.nombre}</b> : transportista.nombre}</p>
                                    <div>
                                        <span title="Ver servicios" style={{cursor: 'pointer'}} className="me-3">
                                            <FontAwesomeIcon icon={faEye} size="1x" className="action" color="#215732" onClick={() => handleOpenServicios(transportista)} />
                                        </span>
                                        <span title="Editar" style={{cursor: 'pointer'}} className="me-3">
                                            <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleOpenForm('transportista', transportista)} />
                                        </span>
                                        <span title="Eliminar" style={{cursor: 'pointer'}}>
                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" onClick={() => handleDeleteTarifa(transportista.id)} />
                                        </span>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        )
                    }) }
                </div>
            </div>

            { (openTransportistaForm) && 
                <InfoModal
                    state={openTransportistaForm}
                    title={(isEditTransportista ? 'Editar' : 'Nueva') + ' transportista'}
                    content={
                        <TransportistaForm 
                            transportista={currentTransportista}
                            isEdit={isEditTransportista}
                            onActionDone={handleActionDone}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { (openServiciosList) && 
                <InfoModal
                    state={openServiciosList}
                    title={"Servicios de " + transportistas.find(t => t.id === transportistaId)?.nombre}
                    content={
                        <div className="col-12 d-flex flex-column align-items-end mt-3">
                            <button type="button" className="btn btn-add" onClick={() => handleOpenForm('servicio')}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                </span>
                                Nuevo
                            </button>
                            
                            <div className="w-100 d-flex flex-column mt-4">
                                { (transportistas.find(t => t.id === transportistaId)?.servicios?.length > 0) ?
                                    <ServiciosTable 
                                        servicios={transportistas.find(t => t.id === transportistaId)?.servicios}
                                        onEdit={handleOpenForm}
                                        onDelete={handleDeleteElement}
                                    />
                                    :
                                    <p className="text-center">No hay servicios</p>
                                }
                                
                            </div>
                        </div>
                    }
                    width={'md'}
                    onClose={handleCloseServicios}
                ></InfoModal>
            }

            { (openServiciosForm) && 
                <InfoModal
                    state={openServiciosForm}
                    title={(isEditServicio ? 'Editar' : 'Nueva') + ' servicio'}
                    content={
                        <ServicioEnvioForm 
                            servicio={currentServicio} 
                            transportistaId={transportistaId}
                            isEdit={isEditServicio}
                            onActionDone={handleActionDone}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { isForDelete && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={isForDelete}>
                </ConfirmModal>
            }
        </div>
    )
}