import API from '../../../../../services/api';

export const getAvisosProveedor = async (page, type = null, proveedorId = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const search = type !== null ? '&search=' + type : '&search=';
    const proveedor = proveedorId !== null ? '&proveedor_id=' + proveedorId : '';
    return API.get('/avisos-proveedor' + pagination + search + proveedor).then(res => res.data);
}

export const bloquearTodosTipoPiezas = (tipo) => {
    return API.post('/avisos-proveedor/bloquear-todos', tipo).then(res => res.data);
}