import React, { useState } from "react";
import Searcher from "./components/Searcher";
import ResultsList from "./components/ResultsList";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import Filter from "./components/Filter";
import { getPiezasIntegraciones } from "../../../../../../../../../../../shared/services/piezas";
import { obtainCats } from "../../../../../../../../../../../../../../utils/categoriasmap";
import Loading from "../../../../../../../../../../../shared/components/Loading";
import { checkAndCreateBloqueoLog, getPiezasGarantia2anyos, getPiezasNoGarantia } from "../../../../../../../../../../../shared/helpers/functionalities";

export default function PiezasSearcher({
    onResultSelected,
    onCancel,
    onBlockedPart
}) {
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const marcas = useSelector(state => {
        const { marcas } = state;
        return marcas;
    });
    const modelos = useSelector(state => {
        const { modelos } = state;
        return modelos;
    });

    const [filters, setFilters] = useState(null);
    const [results, setResults] = useState(null);
    const [currentPage, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const handleSearch = async(data, page = 1) => {
        setIsLoading(true);
        const resultsResponse = await getPiezasIntegraciones(data, page);

        if(resultsResponse && resultsResponse.success) {
            setResults(resultsResponse.data);
            setFilters(data);
            setIsLoading(false);
        }
    }

    const handleFilter = async(data, page = 1) => {
        setIsLoading(true);
        setFilters(data);
        delete data.tipo_pieza;
        data = {...filters, ...data};
        const resultsResponse = await getPiezasIntegraciones(data, page);

        if(resultsResponse && resultsResponse.success) {
            setResults(resultsResponse.data);
            setIsLoading(false);
        }
    }

    const handlePageChange = (event, newPage) => { 
        setIsLoading(true);
        setPage(newPage); 
        handleSearch(filters, newPage);
    }
    
    const getPaginationCount = () => {
        var totalPages = results.total_items < 15 ? 1 : Math.ceil(results.total_items / 15);
        return parseInt(totalPages);
    }

    const handleMakePieza = async(pieza) => {
        const tipo = getTipo(pieza.nombre);

        const isBloqued = checkAndCreateBloqueoLog(pieza.proveedor, tipo);

        if(isBloqued) {
            onBlockedPart(pieza.proveedor, tipo);
            return;
        }

        let precioTransporte = 0;
        let garantiaId = null;

        if(tipo === 406) { precioTransporte = 80 }
        else if(tipo === 55)  { precioTransporte = 40 }
        else {
            if(tipo === 80 &&  pieza.proveedor.envio_capot !== null) {
                precioTransporte =  pieza.proveedor.envio_capot;
            } else if((tipo >= 146 && tipo <= 151)  &&  pieza.proveedor.envio_puertas !== null) {
                precioTransporte =  pieza.proveedor.envio_puertas;
            } else if((tipo >= 174 && tipo <= 176) &&  pieza.proveedor.envio_porton !== null) { 
                precioTransporte =  pieza.proveedor.envio_porton;
            } else if((tipo === 94 || tipo === 172) &&  pieza.proveedor.envio_paragolpes !== null) {
                precioTransporte =  pieza.proveedor.envio_paragolpes;
            } else if((tipo === 70 || tipo === 71 || tipo === 1083 || tipo === 1733) &&  pieza.proveedor.envio_aleta !== null) {
                precioTransporte =  pieza.proveedor.envio_aleta;
            } else if((tipo >= 219 && tipo <= 223) &&  pieza.proveedor.envio_transmision !== null) { 
                precioTransporte =  pieza.proveedor.envio_transmision;
            } else if((tipo === 96 || tipo === 179 || tipo === 1519) &&  pieza.proveedor.envio_refuerzo_paragolpes !== null) {
                precioTransporte =  pieza.proveedor.envio_refuerzo_paragolpes;
            }
        }

        if(getPiezasNoGarantia().find(tp => tp === tipo)) {
            garantiaId = 5;
        } else if(getPiezasGarantia2anyos().find(tp => tp === tipo)) {
            garantiaId = 4;
        } else {
            garantiaId = pieza.proveedor.tipo_garantia_id ? pieza.proveedor.tipo_garantia_id : 1;
        }

        const newPieza = {
            url: 'integracion-desguace-' + pieza.origen,
            nombre: pieza.nombre + ' ' + pieza.marca + ' ' + pieza.modelo,
            referencia_pieza: pieza.referencia_pieza,
            id_referencia_plataforma: pieza.id_desguace,
            precio_original: parseInt(pieza.precio) === 0 ? '0.01' : parseFloat(pieza.precio).toFixed(2),
            precio_compra: parseInt(pieza.precio) === 0 ? '0.01' : parseFloat(pieza.precio).toFixed(2),
            precio_transporte: precioTransporte,
            casco: 0,
            modelo_id: pieza.modelo ? getModelo(pieza.modelo) : null,
            marca_id: pieza.marca ? getMarca(pieza.marca) : null,
            tipo_pieza_id: tipo,
            tipo_garantia_id: garantiaId,
            observaciones: pieza.notapublica,
            proveedor: pieza.proveedor,
            con_descuento: pieza.proveedor.descuentos?.length > 0 ? true : false,
            disponible: pieza.ubicacion.toLowerCase().includes('montada') ? null : 1,
            integracion: true,
            pieza_integracion_id: pieza.id,
            unidades: 1,
            integracion_json: JSON.stringify(pieza)
        }

        onResultSelected(newPieza);
    }

    const getTipo = (tipo) => {
        const cats = obtainCats()
        let id = cats.find(tp => tp.nombre.toLowerCase() ===  tipo.toLowerCase())?.idcrm;
        if (!id){
            id = tipoPiezas.find(tp => tp.nombre.toLowerCase() ===  tipo.toLowerCase())?.id; 
        }
        return id
    }

    const getMarca = (marca) => { return marcas.find(m => m.nombre.toLowerCase() ===  marca?.toLowerCase())?.id; }

    const getModelo = (modelo) => { 
        modelo = modelo.split(' ')[0];
        return modelos.find(m => m.nombre.toLowerCase()?.includes(modelo.toLowerCase()))?.id; 
    }

    return (
        <div className="w-100 d-flex flex-column">
            <Searcher onSearch={handleSearch} onCancel={onCancel} />

            { isLoading ? 
                <Loading />
                : 
                <div className="w-100">
                { results && 
                    <div className="w-100">
                        <Filter 
                            filters={filters}
                            tipoPiezasFiltro={results.tipo_piezas}
                            onFilter={handleFilter} />
                        
                        <ResultsList 
                            results={results} 
                            onPageChange={handleSearch}
                            onSelectResult={handleMakePieza}
                        /> 

                        <Pagination 
                            count={getPaginationCount()} 
                            page={currentPage} 
                            onChange={handlePageChange} 
                        />
                    </div>
                }
                </div>
            }
        </div>
    );
}