import { useEffect, useState } from "react";
import React from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import VirtualizedAutocompletePresupuestos from "../../../../../../../shared/components/AutocompletePresupuestos";

export default function StepForm({
    incidencia,
    item,
    tipoId,
    disabled,
    register,
    control,
    setValue,
    onChangeStep,
    onSetAlternativa
}) {
    const [selectOpt, setSelectOpt] = useState(-1);
    const [viewPresupuesto, setViewPresupuesto] = useState(false);

    const options = [
        "Si",
        "No",
        "La recoge el proveedor.",
        "No se tiene que hacer nada por parte del proveedor.",
        "La mandamos nosotros a través de RF.",
        "La mandamos nosotros a través de Genei.",
        "Sí pero realiza otro pago.",
        "El recambista está buscándola.",
        "Se tiene que devolver el importe total de la factura.",
        "Se tiene que pagar al cliente la factura (escriure nom de la factura de devolució)",
        "Se tiene que pedir y adjuntar el albarán de abono.",
        "Se tiene que pedir y adjuntar la factura de abono y el justificante de pago.",
        "No tenemos que recibir nada.",
        "No se tiene que hacer nada por parte del cliente.",
        "No se tiene que abonar nada, puesto que se envía otro de otro proveedor.",
        "No tenemos que recibir nada.",
        "La mandamos nosotros de vuelta a través de RF, però paga los portes el cliente.",
        "Lo enviamos nosotros de vuelta a través de Genei.",
        "Lo enviamos nosotros de vuelta a través de Genei, però paga los portes el cliente.",
        "Fallo de stock. El proveedor no dispone de la pieza después que el cliente realice el pago.",
        "La enviamos nosotros de vuelta a través del CRM",
        "Se tiene que devolver el importe correspondiente a este componente y al envío de este componente.",
        "Se tiene que devolver el importe correspondiente a este componente.",
        "Se tiene que devolver el importe correspondiente a la fianza.",
        "Se reclama a la agencia de transportes por responsabilidad suya.",
        "Se tiene que abonar X €",
        "Se tiene que devolver al cliente la parte diferencial entre estos presupuestos: (cantidad a devolver)",
        "No se tiene que hacer nada por parte del cliente, puesto que este paga la diferencia con otro presupuesto.",
        "El proveedor ha anulado el albarán de compra.",
        "Se tiene que pedir y adjuntar el vale descuento que nos realiza el proveedor.",
        "El proveedor decide no recogerla.",
        "La mandamos nosotros de vuelta a través del CRM, pero paga los portes el cliente.",
        "El cliente desiste de la compra después de realizar el pago y que le llegue la pieza.",
        "Vamos a crédito.",
        "Se genera albarán de abono.",
        "No se ha llegado a enviar",
        "No se ha llegado a facturar",

    ];
    let timeout = null;

    useEffect(() => {
        if(item) {
            if(item.nombre?.toLowerCase().includes('se envía otra pieza') && item.checked) {
                setViewPresupuesto(true);
            } else setViewPresupuesto(false);
        }
    }, [item]);

    const startTimeOut = (item, e) =>  timeout = setTimeout(() => { 
        timeout = null;
        onChangeStep(item, e.target.value)
    }, 600);
    const clearTimeOut = () => timeout ? clearTimeout(timeout) : null;

    const handleChangePresupuestoAlternativa = (presupuesto) => onSetAlternativa(presupuesto);
    
    return (
        <li key={item.id} style={{borderBottom: '1px solid #f4f4f4', paddingBottom: '20px', marginBottom: '20px'}}>
            <input type="hidden" 
                name={`step.${item.index}.id`}
                {...register(`step.${item.index}.id`)} 
                defaultValue={item.logId} 
            />
            <input type="hidden" 
                name={`step.${item.index}.incidencia_step_id`}
                {...register(`step.${item.index}.incidencia_step_id`)} 
                defaultValue={item.incidencia_step_id} 
            />

            <div className="w-100 d-flex flex-column align-items-center">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="flex-row d-flex align-items-center mb-1">
                        <input
                            name={`step.${item.index}.checked`}
                            {...register(`step.${item.index}.checked`)} 
                            onChange={(e) => {
                                setValue(`step.${item.index}.checked`, e.target.checked);
                            }}
                            type="checkbox"
                            defaultChecked={item.checked}
                            disabled={disabled}
                        />
                        <label className="ms-2"> {item.nombre}</label>
                    </div>

                    { (viewPresupuesto) ?
                        <>
                            { (incidencia?.presupuesto_alternativa_id) ?
                                <Link target="_blank" rel="noopener noreferrer" to={"/presupuesto/" + incidencia?.alternativa?.id} title="Ficha presupuesto alternativa" className="text-green fw-bold">
                                    {incidencia?.alternativa?.numero}
                                </Link>
                                :
                                <div className="w-50">
                                    <VirtualizedAutocompletePresupuestos 
                                        id={'alternativa'}
                                        control={control}
                                        disabled={false}
                                        labels={['numero']}
                                        defaultValue={null}
                                        onChange={handleChangePresupuestoAlternativa}
                                    />
                                </div>
                            }
                        </>
                        : null
                    }
                </div>

                <div className="w-100 mt-2">
                    <div className="form-group">
                        <label htmlFor="observaciones">Mensaje predefinido</label>
                        <select 
                            value={selectOpt}
                            disabled={disabled}
                            onChange={(e) => {
                                setSelectOpt(parseInt(e.target.value))
                                setValue(`step.${item.index}.comentario`, options[parseInt(e.target.value)]);
                                if(tipoId === 5) onChangeStep(item, options[parseInt(e.target.value)])
                            }}>
                            <option key={'opt--1'} value={-1}></option>
                            {options.map((opt, i) => <option key={'opt-' + i} value={i}>{opt}</option>)}
                        </select>
                    </div>

                    { (item.comentario) &&
                        <div className="chat-info" style={{position: 'unset'}}>
                            <span className="me-3">{moment(item.fecha_comentario).format("DD-MM-YYYY hh:mm:ss")}</span>
                            <span className="text-uppercase">{item.created_by?.username}</span>
                        </div>
                    }
                    <textarea
                        className="w-100"
                        defaultValue={item.comentario}
                        disabled={disabled}
                        name={`step.${item.index}.comentario`}
                        {...register(`step.${item.index}.comentario`)} 
                        onKeyUp={(e) => {
                            e.preventDefault();
                            setValue(`step.${item.index}.comentario`, e.target.value);
                            
                            if(tipoId === 5) {
                                clearTimeOut();
                                startTimeOut(item, e);
                            }
                        }}
                    ></textarea>
                </div>
            </div>
        </li>
    )
}