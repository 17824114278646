import React from 'react';
import VerticalBarChart from '../../../../../../../shared/components/Charts/components/VerticalBar';

export default function UserMetrics({currentUser, view}) {
        return (
            <div className='w-100 d-flex flex-column align-items-center justify-content-between my-3'>
                <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap'>
                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center'>
                    { (view === 'dia') && <h2>Presupuestos diarios</h2>}
                        { (view === 'semana') && <h2>Presupuestos semanales</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Presupuestos mensuales</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Presupuestos anuales</h2>}
                        { (view === 'ultimos-12meses') && <h2>Presupuestos</h2>}
                        <h1>{currentUser.num_presupuestos}</h1>
                    </div>

                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    { (view === 'dia') && <h2>Ventas diarias</h2>}
                        { (view === 'semana') && <h2>Ventas semanales</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Ventas mensuales</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Ventas anuales</h2>}
                        { (view === 'ultimos-12meses') && <h2>Ventas</h2>}
                        <h1>{currentUser.num_ventas}</h1>
                    </div>
                </div>

                <div className='card-grid w-100 d-flex mt-4 flex-wrap flex-md-nowrap'>
                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center'>
                        { (view === 'dia') && <h2>Facturación diaria</h2>}
                        { (view === 'semana') && <h2>Facturación semanal</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Facturación mensual</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Facturación anual</h2>}
                        { (view === 'ultimos-12meses') && <h2>Facturación</h2>}
                        <h1>{currentUser.facturacion_total}€</h1>
                    </div>

                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        { (view === 'dia') && <h2>Beneficio diario</h2>}
                        { (view === 'semana') && <h2>Beneficio semanal</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Beneficio mensual</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Beneficio anual</h2>}
                        { (view === 'ultimos-12meses') && <h2>Beneficio</h2>}
                        <h1>{currentUser.beneficio_total}€</h1>
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <VerticalBarChart 
                            title={'Número de presupuestos, ventas y devoluciones'} 
                            dataset={currentUser.num_chart}
                        />
                    </div>
                    
                    <div className="col-12 col-md-6 ps-0 ps-md-3">
                        <VerticalBarChart 
                            title={'Facturación y Beneficios'} 
                            dataset={currentUser.facturacion_chart}
                        />
                    </div>
                </div>
            </div>
        )
}
