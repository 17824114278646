import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function SolicitudProveedorForm({
    incidencia,
    onSubmit
}) {
    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            tipo: ''
        }
    });

    useEffect(() => {
    }, []);

    const handleSubmitSolicitud = async(data, e) => {
        e.preventDefault();
        data.incidencia_id = incidencia.id;
        data.proveedor_id = incidencia.proveedor.id;
        onSubmit(data);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitSolicitud)}>
                <div className="form-group">
                    <label htmlFor="tipo" className="me-3">Tipo de solicitud:</label>
                    
                    <div className="d-flex flex-column mt-2">
                        { (incidencia.proveedor.credito) ?
                            <div>
                                <input
                                    {...register("tipo", { required: true })}
                                    type="radio"
                                    name="tipo"
                                    value={"abono-albaran"}
                                    id="tipo"
                                />
                                &nbsp; Abono del albarán
                            </div>
                            :
                            <div>
                                <input
                                    {...register("tipo", { required: true })}
                                    type="radio"
                                    name="tipo"
                                    value={"abono-factura"}
                                    id="tipo"
                                />
                                &nbsp; Abono de la factura
                            </div>
                        }

                        <div className="my-3">
                            <input
                                {...register("tipo", { required: true })}
                                type="radio"
                                name="tipo"
                                value={"justificante"}
                                id="tipo"
                            />
                            &nbsp; Justificante de pago
                        </div>

                        <div>
                            <input
                                {...register("tipo", { required: true })}
                                type="radio"
                                name="tipo"
                                value={"abono-justificante"}
                                id="tipo"
                            />
                            &nbsp; Abono y justificante
                        </div>
                    </div>

                    <div className="form-invalid">
                        {errors.tipo && "El tipo de solicitud es obligatorio"}
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}