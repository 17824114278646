import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { ProgressBar } from "react-bootstrap";
import { calculatePresupuesto, changeClientePresupuesto, eliminarPieza, fetchPresupuesto, setSelectedDireccionOnPresupuesto } from "../../../../../../../../redux/features/fichaPresupuesto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Loading from "../../../../../shared/components/Loading";
import PresupuestoForm from "./components/PresupuestoForm";
import PiezasList from "./components/PiezasList";
import PresupuestoTotalTable from "./components/PresupuestoTotalTable";
import ClienteWizard from "./components/ClienteWizard";
import PiezasWizard from "./components/PiezasWizard";
import EntityCard from "../../../../../shared/components/EntityCard";
import { comprobarEntidad } from "../../../../../shared/services/clientes";
import InfoModal from "../../../../../shared/components/InfoModal";


export default function Presupuesto({
    presupuestoId,
    onOpenInfo,
    onPresupuesto
}) {
    const dispatch = useDispatch();
    const [isClientSelected, setIsClientSelected] = useState(false);
    const [isChangeCliente, setIsChangeCliente] = useState(false);
    const [isEditCliente, setIsEditCliente] = useState(false);
    const [isNewPieza, setIsNewPieza] = useState(false);
    const [isEditPieza, setIsEditPieza] = useState(false);
    const [isDuplicatePieza, setIsDuplicatePieza] = useState(false);
    const [isDeletePieza, setIsDeletePieza] = useState(false);
    const [checkIfExistsInOtherPresupuesto, setCheckIfExistsInOtherPresupuesto] = useState(false);
    const [openClienteWarning, setOpenClienteWarning] = useState(false);

    const presupuesto = useSelector(state => {
        return state.presupuesto.entity
    })
    const status = useSelector(state => {
        return state.presupuesto.status
    })

    useEffect(() => {
        if(presupuestoId > 0) {
            dispatch(fetchPresupuesto(presupuestoId));
            setIsClientSelected(true);
        } else { dispatch({type: "presupuesto/nuevo", payload: null}); }
    }, [presupuestoId]);

    useEffect(() => {
        return () => {
            dispatch({type: "presupuesto/clear", payload: null });
            dispatch({type: 'pieza/clear', payload: null});
            dispatch({type: 'proveedor/clear', payload: null});
            localStorage.setItem('destino_presupuesto', null);
        };
    }, []);

    const handleCliente = (cliente) => {
        if(cliente) {
            setIsEditCliente(false);
            setIsChangeCliente(false);
            dispatch({type: "presupuesto/set-progress", payload: 33});
            dispatch({type: "presupuesto/set-cliente", payload: cliente});
            setIsClientSelected(true);

            // COMPROBAMOS EL CLIENTE
            comprobarCliente(cliente);
        }
    }

    const comprobarCliente = async(cliente) => {
        const response = await comprobarEntidad(cliente.id);

        if(response && response.success) {
            if(response.data.ratio !== null && response.data.ratio <= 10) {
                if(cliente.aviso_incidencias) {
                    setOpenClienteWarning(true);
                }
            }
        }
    }

    const handleCloseWarning = () => setOpenClienteWarning(false);

    const changeClient = () => { setIsChangeCliente(true); setIsEditCliente(false); }

    const handleClienteChange = async (clienteId) => {
        if(clienteId !== false) {
            dispatch({type: 'loading/set', payload: true});
            dispatch(changeClientePresupuesto(presupuestoId, clienteId));
        }
    }

    const handleSelectedDireccion = (direccion) => {         
        if(presupuestoId > 0) {
            dispatch({type: 'loading/set', payload: true});
            dispatch(setSelectedDireccionOnPresupuesto(presupuestoId, direccion.id));
        }
    }
    
    const handleEditCliente = () => {  setIsEditCliente(true); }
    const handleCancelEditCliente = () => {  setIsEditCliente(false); }
    const handleCancelChangeCliente = () => { setIsChangeCliente(false); }

    const newPiezaFlow = () => { setIsNewPieza(true); }

    const handleCancelPiezas = () => { 
        setIsEditPieza(false);
        setIsDeletePieza(false);
        setIsNewPieza(false);
        setIsDuplicatePieza(false);
        dispatch({type: 'pieza/clear', payload: null});
        dispatch({type: 'proveedor/clear', payload: null});
    }

    const handlePiezasAction = (action, pieza) => {
        dispatch({type: "pieza/fullfilled", payload: pieza});

        if(action === 'edit') {
            setIsEditPieza(true);
            setIsDuplicatePieza(false);
            setIsDeletePieza(false);
        } else if(action === 'duplicate') {
            setIsDuplicatePieza(true);
            setIsEditPieza(false);
            setIsDeletePieza(false);
        } else if(action === 'delete') {
            setIsDeletePieza(true);
            setIsEditPieza(false);
            setIsDuplicatePieza(false);
        }
    }

    const handlePiezasChange = async(type) => {
        dispatch({type: "presupuesto/set-progress", payload: 66});
        if(presupuestoId === 0) dispatch({type: "presupuesto/calcular", payload: null});

        if(type === 'new') {
            setCheckIfExistsInOtherPresupuesto(true);
        } else setCheckIfExistsInOtherPresupuesto(false);
    }

    const handleDeletePieza = async(pieza) => {    
        dispatch({type: 'loading/set', payload: true});
        dispatch(eliminarPieza(pieza, presupuestoId > 0 ? presupuestoId : null));
        dispatch({type: 'pieza/clear', payload: null});
        dispatch({type: 'proveedor/clear', payload: null});
        setIsDeletePieza(false);
    }

    const calcularTotalPresupuesto = () => {
        if(presupuestoId > 0) {
            dispatch({type: 'loading/set', payload: true});
            dispatch(calculatePresupuesto(presupuesto.id));
        } else  { dispatch({type: "presupuesto/calcular", payload: null}); } 
    }


    if((status.loading === 'pending' || status.loading === 'idle') && presupuestoId > 0) {
        return ( <Loading /> );
    }

    if((status.loading === 'succeed' || status.loading === 'rejected') || presupuestoId === 0) {
        return (
            <div className="w-100">
                { (isClientSelected || presupuesto?.cliente) &&
                    <EntityCard
                        cliente={presupuesto.cliente} 
                        canEdit={true}
                        canChange={(presupuestoId > 0 && !presupuesto.es_venta) ? true : false}
                        onEdit={handleEditCliente} 
                        onChange={changeClient}
                        direccionEnvioId={presupuesto?.direccion_envio_id}
                    />
                }

                { (presupuestoId === 0) ?
                    <div className="w-100 d-flex flex-column my-4">
                        <ProgressBar className="w-100" now={presupuesto.progress} />

                        { ((isClientSelected || presupuesto?.cliente)) && 
                            <div className="w-100 d-flex align-items-center justify-content-end mt-3">
                                <button type="button" className="btn btn-add" onClick={() => newPiezaFlow()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                    </span>
                                    Añadir pieza
                                </button>
                            </div>
                        }
                    </div>
                    :
                    <div className="w-100 d-flex align-items-center justify-content-between my-4">
                        <div className="d-flex flex-column flex-grow-1">
                            <h1 className="text-success mb-2">Presupuesto</h1>
                            <h3>{presupuesto.numero}</h3>
                            <p>{moment(presupuesto.fecha).format("DD/MM/YYYY")}</p>
                            { presupuesto?.codigo_autorizacion ? <p>Código de autorización: {presupuesto.codigo_autorizacion}</p> : ''}
                        </div>

                        <div className="d-flex flex-column justify-content-between align-items-end">
                            <span className="mb-3" title="Información">
                                <FontAwesomeIcon icon={faInfoCircle} size="lg" className="action" color="#62B1F6" style={{ cursor: 'pointer' }} onClick={() => onOpenInfo()} />
                            </span>

                            { (!presupuesto?.es_venta) &&
                                <button type="button" className="btn btn-add" onClick={() => newPiezaFlow()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                    </span>
                                    Añadir pieza
                                </button>
                            }
                        </div>
                    </div>
                }

                { ((isClientSelected || presupuesto?.cliente) && presupuesto.piezas?.length > 0) && 
                    <div className="col-12">
                        <PiezasList 
                            esVenta={presupuesto?.es_venta}
                            piezas={presupuesto.piezas} 
                            iva={presupuesto.iva} 
                            presupuesto={presupuesto}
                            check={checkIfExistsInOtherPresupuesto}
                            onAction={handlePiezasAction}
                        />
                    </div>
                }
                   
                { (isClientSelected || presupuesto?.cliente) && 
                    <div className="col-12 d-flex flex-wrap justify-content-between mt-3">
                        <div className="col-12 col-md-6 pe-lg-5">
                            <PresupuestoForm 
                                presupuestoId={presupuestoId}
                                presupuesto={presupuesto}
                                esCredito={presupuesto.cliente?.credito}
                                codigo={presupuesto?.codigo_autorizacion}
                                observaciones={presupuesto?.observaciones}
                                bastidor={presupuesto?.bastidor}
                                link_catalogo={presupuesto?.link_catalogo}
                                onSubmit={onPresupuesto}
                            />
                        </div>

                        <div className="col-12 col-md-6 d-flex flex-column">
                            { (presupuesto.piezas?.length > 0) &&
                                <PresupuestoTotalTable
                                    total={presupuesto.total}
                                    envio={presupuesto.total_envio}
                                    casco={presupuesto.total_casco}
                                    iva={presupuesto.iva}
                                    total_iva={presupuesto.total_iva}
                                    total_descuento_iva={presupuesto.total_descuento_iva}
                                    valeDescuento={presupuesto.vale_descuento}
                                />
                            }
                        </div>
                    </div>
                }

                <ClienteWizard
                    presupuestoId={presupuestoId}
                    esVenta={presupuesto.es_venta}
                    presupuesto={presupuesto}
                    cliente={presupuesto.cliente}
                    isEdit={isEditCliente}
                    isChange={isChangeCliente}
                    selectedDireccionId={presupuesto?.direccion_envio_id}
                    onSelectedCliente={handleCliente}
                    onSelectedDireccion={handleSelectedDireccion}
                    onClienteChange={handleClienteChange}
                    onCancelEditCliente={handleCancelEditCliente}
                    onCancelChangeCliente={handleCancelChangeCliente}
                />

                <PiezasWizard
                    cliente={presupuesto.cliente}
                    esVenta={presupuesto.es_venta}
                    presupuestoId={presupuestoId}
                    isNew={isNewPieza}
                    isEdit={isEditPieza}
                    isDuplicate={isDuplicatePieza}
                    isForDelete={isDeletePieza}
                    selectedDireccionId={presupuesto?.direccion_envio_id}
                    tarifaClienteId={presupuesto?.cliente?.tarifa_cliente_id}
                    onPiezasResult={handlePiezasChange}
                    onServicioEnvio={calcularTotalPresupuesto}
                    onAddedImages={calcularTotalPresupuesto}
                    onDeletePieza={handleDeletePieza}
                    onCancel={handleCancelPiezas}
                />

                { (openClienteWarning) && 
                    <InfoModal
                        state={openClienteWarning}
                        title={'Aviso de ratio de conversión bajo'}
                        content={
                            <div>
                                <div className="w-100 d-flex align-items-start">
                                    <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" />
                                    <p className="ms-2"> Se ha informado a incidencias a cerca de este cliente por que tiene un ratio de conversión bajo.</p>
                                </div>
                            </div>
                        }
                        width={'sm'}
                        onClose={handleCloseWarning}
                    ></InfoModal>
                }
            </div>
        )
    }
}