import { useState } from "react";
import React from "react";
import moment from 'moment';
import { useSelector } from "react-redux";
import CustomDatePicker from "../CustomDatePicker";
import { Switch } from "@mui/material";
import AutocompleteSelect from "../AutocompleteSelect";
import VirtualizedAutocompletePersonas from "../AutocompletePersonas";
import { useForm } from "react-hook-form";
import { getAllowedExt } from "../../helpers/functionalities";

export default function ExportForm({
    dateFilter,
    clienteFilter,
    tipoClienteFilter,
    tipoFacturasFilter,
    tipoPiezasFilter,
    transportistasFilter,
    responsableFilter,
    perdidasFilter,
    fileFilter,
    onSubmitExport
}) {
    const { 
        control,
        register,
        setValue,
        handleSubmit 
    } = useForm({
        defaultValues: {
            cliente: null,
            excel: ''
        }
    });

    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [tipoCliente, setTipoCliente] = useState(-1);
    const [exportVentas, setExportVentas] = useState(true);
    const [exportModificadas, setExportModificadas] = useState(true);
    const [exportRectificadas, setExportRectificadas] = useState(true);
    const [exportCredito, setExportCredito] = useState(true);
    const [responsable, setResponsable] = useState(-1);
    const [perdidas, setPerdidas] = useState(null);
    const [tipoPiezasSelected, setTipoPiezasSelected] = useState([]);
    const [exportTodos, setExportTodos] = useState(true);
    const [exportProveedor, setExportProveedor] = useState(false);
    const [exportExpressway, setExportExpressway] = useState(false);
    const [exportGenei, setExportGenei] = useState(false);
    const recambistas = useSelector(state => {
        return state.recambistas
    });
    const tipoClientes = useSelector(state => {
        const { tipo_clientes } = state;
        return tipo_clientes;
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });

    const handleChangeCliente = (cliente) => { setCliente(cliente); setValue('cliente', cliente); }
    const handleChangeTipoClientes = (e) => { setTipoCliente(parseInt(e.target.value)); }
    const handleChangeVentas = (e) => { setExportVentas(e.target.checked); }
    const handleChangeModificadas = (e) => { setExportModificadas(e.target.checked); }
    const handleChangeRectificadas = (e) => { setExportRectificadas(e.target.checked); }
    const handleChangeCredito = (e) => { setExportCredito(e.target.checked); }
    const handleResponsable = (e) => { setResponsable(parseInt(e.target.value)); }
    const handleChangePerdidas = (e) => { setPerdidas(e.target.value); }
    const handleChangeTipoPiezas = (tipo) => { setTipoPiezasSelected(tipo); }
    const handleChangeTodos = (e) => {
        const value = e.target.checked;

        if(value) {
            setExportProveedor(false);
            setExportExpressway(false);
            setExportGenei(false);
        }

        setExportTodos(value);
    }
    const handleChangeProveedor = (e) => {
        const value = e.target.checked;

        if(value) { setExportTodos(false); }

        setExportProveedor(value);
    }
    const handleChangeExpressway = (e) => {
        const value = e.target.checked;

        if(value) { setExportTodos(false); }

        setExportExpressway(value);
    }
    const handleChangeGenei = (e) => {
        const value = e.target.checked;

        if(value) { setExportTodos(false); }

        setExportGenei(value);
    }

    const isExportDisabled = () => {
        let disabled = false;

        if(tipoFacturasFilter) disabled = (exportVentas || exportModificadas || exportRectificadas || exportCredito) ? false : true;
        if(tipoPiezasFilter) disabled = (tipoPiezasSelected && tipoPiezasSelected?.length > 0) ? false : true;
        if(perdidasFilter) disabled = (perdidas) ? false : true;
        if(transportistasFilter) disabled = (exportTodos || exportProveedor || exportExpressway || exportGenei) ? false : true;
        if(dateFilter) disabled = (startDateValue !== null && endDateValue !== null) ? false : true ;
        if(clienteFilter) disabled = (cliente !== null ? false : true);

        return disabled;
    }

    const handleSubmitExport = (data) => {
        let dataAux = {};
        if(dateFilter) {
            dataAux = {
                startDate: startDateValue ? moment(startDateValue).format('YYYY-MM-DD') : null,
                endDate: endDateValue ? moment(endDateValue).format('YYYY-MM-DD') : null,
            };
        }

        if(clienteFilter) { dataAux.cliente = cliente; }

        if(tipoFacturasFilter) {
            let type;

            if(exportVentas && exportModificadas && exportRectificadas) type = 0; // ALL
            else {
                type = [];

                if(exportVentas) type.push(1);
                if(exportModificadas) type.push(2);
                if(exportRectificadas) type.push(3);

                if(!exportVentas && !exportModificadas && !exportRectificadas) type = null;
            }

            dataAux.type = type;
        }

        if(transportistasFilter) {
            let type;

            if(exportTodos) type = 'all';
            else {
                type = [];

                if(exportProveedor) type.push('proveedor');
                if(exportExpressway) type.push('expressway');
                if(exportGenei) type.push('genei');
            }

            dataAux.type = type;
        }

        if(tipoClienteFilter) dataAux.type = tipoCliente;
        if(tipoPiezasFilter) dataAux.tipo_piezas = tipoPiezasSelected.map(tp => tp.id);
        if(responsableFilter) dataAux.responsable = responsable;
        if(perdidasFilter) dataAux.export = perdidas;

        if(fileFilter) {
            const excel = new FormData();
            excel.append('excel', data.excel[0]);
            onSubmitExport(excel);
            return;
        }

        onSubmitExport(dataAux);
    };

    return (
        <div className="w-100">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitExport)}>
                <div className="w-100 d-flex flex-column">
                    { (dateFilter) &&
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <div className="form-group me-0 me-md-3">
                                <CustomDatePicker 
                                    label={"Fecha inicial"}
                                    disableFuture={true}
                                    minDateValue={null}
                                    onChangeDate={setStartDateValue}
                                />
                            </div>
                            
                            <div className="form-group">
                                <CustomDatePicker 
                                    label={"Fecha final"}
                                    disableFuture={true}
                                    minDateValue={startDateValue}
                                    onChangeDate={setEndDateValue}
                                />
                            </div>
                        </div>
                    }

                    { (clienteFilter) &&
                        <div className="form-group">
                            <VirtualizedAutocompletePersonas 
                                id={"cliente"}
                                control={control}
                                item={'cliente'}
                                labels={['nombre', 'cif', 'telefono']}
                                defaultValue={cliente}
                                onChange={handleChangeCliente}
                            />
                        </div>
                    }

                    { (fileFilter) &&
                        <div className="form-group">
                            <label htmlFor="excel">Excel</label>
                            <input 
                                type="file" 
                                className="form-control" 
                                accept={getAllowedExt('excel')}
                                {...register("excel")} 
                                defaultValue={''} />
                        </div>
                    }


                    <div className="w-100">
                        { (tipoPiezasFilter) &&
                            <div className="w-100 d-flex align-items-center justify-content-between">
                                <div className="form-group">
                                    <label>Tipo de piezas</label>
                                    <AutocompleteSelect 
                                        options={tipoPiezas}
                                        id={'tipoPiezas'}
                                        groupBy={"familia"}
                                        selectedValues={[]}
                                        onSetValue={handleChangeTipoPiezas}
                                    />
                                </div>
                            </div>
                        }

                        { (tipoClienteFilter) &&
                            <div className="w-100 d-flex align-items-center justify-content-between">
                                <div className="form-group">
                                    <label htmlFor="tipo_cliente_id">Tipo cliente</label>
                                    { (tipoClientes && tipoClientes.length > 0) &&
                                        <select 
                                            name="tipo_cliente_id"
                                            defaultValue={-1}
                                            onChange={handleChangeTipoClientes}>
                                            <option value={"-1"}>Todos</option>
                                            {tipoClientes.map(op => <option key={'tipo-clientes-' + op.id} value={op.id}>{op.nombre}</option>)}
                                        </select>
                                    }
                                </div>
                            </div>
                        }

                        { (tipoFacturasFilter) &&
                            <div className="w-100 d-flex align-items-center justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <Switch 
                                        checked={exportVentas}
                                        onChange={handleChangeVentas}
                                        inputProps={{ 'aria-label': 'controlled' }} /> 
                                    <label>Ventas</label>
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <Switch 
                                        checked={exportModificadas}
                                        onChange={handleChangeModificadas}
                                        inputProps={{ 'aria-label': 'controlled' }} /> 
                                    <label>Modificadas</label>
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <Switch 
                                        checked={exportRectificadas}
                                        onChange={handleChangeRectificadas}
                                        inputProps={{ 'aria-label': 'controlled' }} /> 
                                    <label>Rectificadas</label>
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <Switch 
                                        checked={exportCredito}
                                        onChange={handleChangeCredito}
                                        inputProps={{ 'aria-label': 'controlled' }} /> 
                                    <label>Credito</label>
                                </div>
                            </div>
                        }

                        { (transportistasFilter) &&
                            <div className="w-100 d-flex align-items-center justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <Switch 
                                        checked={exportTodos}
                                        onChange={handleChangeTodos}
                                        inputProps={{ 'aria-label': 'controlled' }} /> 
                                    <label>Todos</label>
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <Switch 
                                        checked={exportProveedor}
                                        onChange={handleChangeProveedor}
                                        inputProps={{ 'aria-label': 'controlled' }} /> 
                                    <label>Proveedor</label>
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <Switch 
                                        checked={exportExpressway}
                                        onChange={handleChangeExpressway}
                                        inputProps={{ 'aria-label': 'controlled' }} /> 
                                    <label>ExpressWAY</label>
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <Switch 
                                        checked={exportGenei}
                                        onChange={handleChangeGenei}
                                        inputProps={{ 'aria-label': 'controlled' }} /> 
                                    <label>Genei</label>
                                </div>
                            </div>
                        }

                        { (responsableFilter) &&
                            <div className="w-100 d-flex align-items-center mt-3">
                                <div className="form-group">
                                    <label htmlFor="responsable">Responsable:</label>
                                    { (recambistas && recambistas.length > 0) &&
                                        <select 
                                            id="responsable"
                                            defaultValue={responsable}
                                            onChange={handleResponsable}>
                                            <option key={'recambistas-' + 0} value={-1}>Todos</option>
                                            {recambistas.map(op => <option key={'recambistas-' + op.id} value={op.id}>{op.username}</option>)}
                                        </select>
                                    }
                                </div>
                            </div>
                        }

                        { (perdidasFilter) &&
                            <div className="d-flex align-items-center mt-2">
                                <div className="me-3">
                                    <input
                                        type="radio"
                                        name="tipo_perdida"
                                        value={"perdida"}
                                        id="tipo_perdida"
                                        onChange={handleChangePerdidas}
                                    />
                                    &nbsp; Pérdida de recambistas
                                </div>

                                <div>
                                    <input
                                        type="radio"
                                        name="tipo_perdida"
                                        value={"perdida_comercial"}
                                        id="tipo_perdida"
                                        onChange={handleChangePerdidas}
                                    />
                                    &nbsp; Pérdida comercial
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="w-100 d-flex align-items-center justify-content-end mt-4">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        disabled={isExportDisabled()}>Exportar</button>
                </div>
            </form>
        </div>
    );
}