import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { newStep, updateStep } from "../../../../../shared/services/incidenciasSteps";

export default function StepForm({step, isEdit, onCancel, onActionDone}) {
    const dispatch = useDispatch();
    const tipoIncidencias = useSelector(state => {
        const { tipo_incidencias } = state;
        return tipo_incidencias;
    });
    const { register, formState: { errors }, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: {
            id: 0,
            nombre: '',
            step: 1,
            owner: '',
            solucion: 0,
            tipo_incidencia_id: -1
        }
    });

    useEffect(() => {
        reset(step);
    }, [step]);

    const handleChangeTipoIncidencia = (e) => { setValue('tipo_incidencia_id', parseInt(e.target.value)); }

    const handleSubmitTarifa = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});
        let response;

        data.tipo_incidencia_id = parseInt(data.tipo_incidencia_id) === -1 ? null : data.tipo_incidencia_id;
        
        if(isEdit) {
            response = await updateStep(data);
        } else {
            response = await newStep(data);
        }

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onActionDone();
        }
    }

    const handleCancel = () => onCancel(isEdit ? 'edit' : 'new');
    
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitTarifa)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? step.id : ''} />

                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="nombre">Nombre</label>
                        <input 
                            type="text" 
                            className="form-control"
                            {...register("nombre", { required: true })} 
                            defaultValue={isEdit ? step.nombre : 0} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "Nombre es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="nombre">Paso</label>
                        <input 
                            type="number" 
                            step={1}
                            className="form-control"
                            {...register("step", { required: true })} 
                            defaultValue={isEdit ? step.step : 1} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "Nombre es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="tipo" className="me-3">Tipo</label>
                        <div className="d-flex align-items-center mt-2">
                            <div className="me-3">
                                <input
                                    {...register("owner", { required: true })}
                                    type="radio"
                                    name="owner"
                                    value={"cliente"}
                                    id="owner"
                                />
                                &nbsp; Cliente
                            </div>

                            <div>
                                <input
                                    {...register("owner", { required: true })}
                                    type="radio"
                                    name="owner"
                                    value={"proveedor"}
                                    id="owner"
                                />
                                &nbsp; Proveedor
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="tipo_incidencia_id">Tipo de incidencia (opcional)</label>
                        { (tipoIncidencias && tipoIncidencias.length > 0) &&
                            <select 
                                {...register("tipo_incidencia_id", {required: true})}
                                defaultValue={isEdit ? parseInt(step.tipo_incidencia_id) : -1}
                                onChange={handleChangeTipoIncidencia}>
                                <option key={'tipo-incidencias-0'} value={-1}>Todas</option>
                                {tipoIncidencias.map(op => <option key={'tipo-incidencias-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }
                    </div>

                    <div className="form-group flex-row align-items-center">
                        <label className="m-0 me-3">Solución:</label>

                        <input 
                            type="checkbox"
                            {...register("solucion")}
                            name={`solucion`}
                            defaultChecked={step ? step.solucion : false} />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    <button type="button" className="btn btn-default align-self-end ms-3" onClick={handleCancel}>Cancelar</button>
                </div>
            </form>
        </div>
    )
}