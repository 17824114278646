import API from '../../../../../services/api';

export const getPoblaciones = async () => {
    return await API.get('/poblaciones').then(res => res.data);
}

export const seachPoblaciones = async (idPais, search) => {
    return await API.get('/paises/poblaciones/' + idPais + "?search=" + search).then(res => res.data);
}

export const getPoblacion = async (idPoblacion) => {
    return API.get('/poblaciones/' + idPoblacion).then(res => res.data);
}

export const newPoblacion = async (poblacion) => {
    return API.post('/poblaciones', poblacion).then(res => res.data);
}

export const updatePoblacion = async (poblacion) => {
    return API.put('/poblaciones', poblacion).then(res => res.data);
}

export const deletePoblacion = async (idPoblacion) => {
    return API.delete('/poblaciones/' + idPoblacion).then(res => res.data);
}