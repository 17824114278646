import { useEffect } from "react";
import React from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserResume } from "../../../../../../redux/features/dashboard";

export default function UserInfo() {
    const dispatch = useDispatch();
    const dashboard = useSelector(state => {
        return state.dashboard.entity.user.metrics
    });

     useEffect(() => {
        if(!dashboard) {
            const fechas = getFechas();
            dispatch(fetchUserResume(fechas.inicio, fechas.fin));
        }
    }, []);

    const getFechas = () => {
        const start = moment().date() >= 26 ?
            moment().date(26).format('YYYY-MM-DD')
        :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD');
        const end = moment().date() >= 26 ? 
            moment().add(1, 'month').date(25).format('YYYY-MM-DD')
        :   moment().date(25).format('YYYY-MM-DD'); 

        return {inicio: start, fin: end}; 
    }
    const getFechaInicio = () => { 
        return moment().date() >= 26 ?
                moment().date(26).format('DD MMM YYYY')
            :   moment().subtract(1, 'month').date(26).format('DD MMM YYYY') 
    }
    const getFechaFin = () => { 
        return moment().date() >= 26 ? 
            moment().add(1, 'month').date(25).format('DD MMM YYYY')
        :   moment().date(25).format('DD MMM YYYY') 
    }


    return (
        <div className='w-100 mt-3'>
            <div className='w-100 mt-3'>
                <div className="w-100 d-flex justify-content-between mb-3">
                    <h1>Resumen</h1>
                    <small>{getFechaInicio()} - {getFechaFin()}</small>
                </div>
                
                <div className='w-100 table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th className='text-center'>presupuestos</th>
                                <th className='text-center'>ventas</th>
                                <th className='text-center'>incidencias</th>
                                <th className='text-center'>ratio conversión</th>
                                <th className='text-center'>ratio beneficio</th>
                                <th className='text-center'>ratio incidencias</th>
                                <th className='text-center'>facturación</th>
                                <th className='text-center'>beneficio</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='text-center'>{dashboard?.num_presupuestos}</td>
                                <td className='text-center'>{dashboard?.num_ventas}</td>
                                <td className='text-center'>{dashboard?.num_incidencias}</td>
                                <td className='text-center'>{dashboard?.ratio_conversion}%</td>
                                <td className='text-center'>{dashboard?.ratio_beneficio}%</td>
                                <td className='text-center'>{dashboard?.ratio_incidencias}%</td>
                                <td className='text-center'>{dashboard?.facturacion_total}€</td>
                                <td className='text-center'>{dashboard?.beneficio_total}€</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="w-100 mt-3">
                <h1>Incidencias</h1>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nº</th>
                                <th className="text-center">fecha</th>
                                <th className="text-center">tipo</th>
                                <th className="text-center">factura</th>
                                <th>cliente</th>
                                <th>proveedor</th>
                                <th>Pieza</th>
                                <th className="text-center">€ venta</th>
                                <th className="text-center">envio</th>
                                <th className="text-center">resp</th>
                                <th className="text-center">creada por</th>
                                <th className="text-center">estado</th>
                                <th className="text-center">
                                    <span title="Solución Proveedor">SP</span>
                                </th>
                                <th className="text-center">
                                    <span title="Solución Cliente">SC</span>
                                </th>
                            </tr>
                        </thead>

                        { dashboard?.incidencias_user?.map((incidencia, index) => {
                                const piezasValues = Object.values(incidencia.piezas);
                                const piezasRows = (piezasValues && piezasValues.length > 0) && piezasValues.map((pieza, i) => {
                                const numero = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%">
                                    {incidencia.numero}
                                </td> : null
                                const tipo = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="8%" className="text-center  text-uppercase">{incidencia.tipo_incidencia?.nombre}</td> : null
                                const factura = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="8%" className="text-center">
                                    {incidencia?.venta?.numero}
                                </td> : null
                                const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="10%" className="text-center">
                                    <div className="w-100 d-flex flex-column">
                                        <p>{moment(incidencia.fecha).format("DD-MM-YYYY")}</p>
                                    </div>
                                </td> : null
                                const envio = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                                    { incidencia.envios?.length > 0 ?
                                        incidencia?.envios?.map(envio => {
                                            return (
                                                <div className="d-flex flex-column mb-2" key={'envio-' + envio.id + '-incidencia-' + incidencia.id}>
                                                    <span className="link">{envio.codigo_transporte}</span>
                                                    <small>{envio.codigo_envio}</small>
                                                </div>
                                            )
                                        })
                                    : '-'
                                    }
                                </td> : null
                                const cliente = i === 0 ?  <td rowSpan={piezasValues.length + 1}>
                                    <div className="d-flex flex-column">
                                    <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + incidencia.cliente?.id} title="Ficha Cliente" className="text-green text-uppercase">
                                        {incidencia.cliente?.nombre}
                                    </Link>
                                    <small>{incidencia.cliente.telefono}</small>
                                    </div>
                                </td> : null
                                const proveedor = i === 0 ?  <td rowSpan={piezasValues.length + 1}>
                                    <div className="d-flex flex-column">
                                    <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + incidencia.proveedor?.id} title="Ficha Proveedor" className="text-green text-uppercase">
                                        {incidencia.proveedor?.nombre}
                                    </Link>
                                    <small>{(incidencia.proveedor?.telefono_incidencias !== "" && incidencia.proveedor?.telefono_incidencias !== null) ? incidencia.proveedor?.telefono_incidencias : incidencia.proveedor?.telefono}</small>
                                    </div>
                                </td> : null
                                const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase">{incidencia.created_by?.username}</td> : null
                                const creadoPor = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase">{incidencia.crafted_by?.username}</td> : null
                                const estado = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="8%" className={"text-center text-uppercase"}>
                                    <span className={"fw-bold d-block " + ((incidencia.estado_incidencia_id === 1 || incidencia.estado_incidencia_id === 4) ? 'text-danger' : 'text-green')}>{incidencia.estado?.nombre}</span>
                                    <small className={"fw-bold d-block " + (incidencia.subestado_incidencia_id === 1 ? 'text-warning' : 'text-info')}>{incidencia.subestado?.nombre}</small>
                                </td> : null
                                const sp = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="5%" className={"text-center " + (incidencia.estado_incidencia_id === 1 ? 'text-danger' : 'text-green')}>
                                    { incidencia.solucion_proveedor ? 
                                    <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                    :
                                    <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                    }
                                </td> : null
                                const sc = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="5%" className={"text-center " + (incidencia.estado_incidencia_id === 1 ? 'text-danger' : 'text-green')}>
                                    { incidencia.solucion_cliente ? 
                                    <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                    :
                                    <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                    }
                                </td> : null
                                
                                return (
                                    <tr key={i}>
                                        {numero}
                                        {fecha}
                                        {tipo}
                                        {factura}
                                        {cliente}
                                        {proveedor}
                                        <td>
                                            <span className="d-block">{pieza.nombre}</span>
                                            <small>#{pieza.id_referencia_plataforma}</small>
                                        </td>
                                        <td className="text-center" width="6%">{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
                                        {envio}
                                        {responsable}
                                        {creadoPor}
                                        {estado}
                                        {sp}
                                        {sc}
                                    </tr>
                                )
                            })
                                return (
                                    <tbody key={index}>
                                        {piezasRows}
                                    </tbody>
                                    )
                            }) 
                            }
                    </table>
                </div>
            </div>
        </div>
    
    )
}