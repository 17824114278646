import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import AutocompleteSelect from "../../../shared/components/AutocompleteSelect";
import { useSelector } from "react-redux";
import VirtualizedAutocomplete from "../../../shared/components/Autocomplete";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Switch } from "@mui/material";

export default function SolicitudForm({
    onSubmitSolicitud
}) {
    const piezasSchema = yup.object({   
        telefono: yup.string().required("El teléfono es obligatorio"),
        referencia_pieza: yup.string().required("La referencia es obligatoria"),
        bastidor: yup.string().required("El bastidor es obligatorio"),
        tipo_pieza_id: yup.number().integer().required("El tipo de pieza es obligatorio"),
        modelos_id:  yup.string().required("El modelo es obligatorio∫"),
    });
    const { register, handleSubmit, setValue, formState: {errors}, control } = useForm({
        resolver: yupResolver(piezasSchema),
        mode: "onChange",
        defaultValues: {
            telefono: '',
            referencia_pieza: '',
            bastidor: '',
            modelos: [],
            ficha_tecnica: '',
            tipo_pieza: null,
            tipo_pieza_id: '',
            modelos_id: '',
            pieza: '',
            comentario: ''
        }
    });
    const modelos = useSelector(state => {
        const { modelos } = state;
        return modelos;
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const [disable, setDisable] = useState(false);
    const [offline, setOffline] = useState(false);
    const [reconstruidos, setReconstruidos] = useState(false);
    const [tipoPiezasDisponibles, setTipoPiezasDisponibles] = useState(tipoPiezas);

    useEffect(() => {
        
    }, []);

    const handleChangeModelos = (modelos) => { 
        setValue('modelos', modelos);
        setValue('modelos_id', modelos.map(m => m.id).join(' ')); 
    }
    const handleTipoPiezaChange = (tipo) => { 
        setValue('tipo_pieza', tipo); 
        setValue('tipo_pieza_id', tipo.id);
    }

    const handleChangeOffline = (e) => { 
        setOffline(e.target.checked); 
        setReconstruidos(e.target.checked ? false : true); 
        setTipoPiezasDisponibles(tipoPiezas);
    }
    const handleChangeReconstruidos = (e) => { 
        setReconstruidos(e.target.checked); 
        setOffline(e.target.checked ? false : true); 
        const tipoPiezasHabilitadas = tipoPiezas.filter(tp => 
            tp.id === 55 || tp.id === 56  || tp.id === 58 || tp.id === 59 || tp.id === 60 || tp.id === 61
            || tp.id === 201 || tp.id === 204 || tp.id === 207 || tp.id === 208 || tp.id === 219 
            || tp.id === 220 || tp.id === 221 || tp.id === 222 || tp.id === 223 || tp.id === 233 || tp.id === 234 
            || tp.id === 235 || tp.id === 236 || tp.id === 237 || tp.id === 238 || tp.id === 239 || tp.id === 240 
            || tp.id === 241 || tp.id === 242 || tp.id === 243 || tp.id === 244 || tp.id === 245 || tp.id === 246 
            || tp.id === 247 || tp.id === 248 || tp.id === 249 || tp.id === 295 || tp.id === 296 || tp.id === 297 
            || tp.id === 298 || tp.id === 299 || tp.id === 300 || tp.id === 301 || tp.id === 302 || tp.id === 303 
            || tp.id === 304 || tp.id === 305 || tp.id === 306 || tp.id === 307 || tp.id === 371 || tp.id === 385 
            || tp.id === 396 || tp.id === 400 || tp.id === 406 || tp.id === 443 || tp.id === 468 || tp.id === 469 
            || tp.id === 503  
        );

        setTipoPiezasDisponibles(tipoPiezasHabilitadas);
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setDisable(true);

        const solicitud = new FormData();

        Object.entries(data).forEach((value) => {
            if((value[0] === 'ficha_tecnica') && value[1]?.length > 0) {
                solicitud.append(value[0], value[1][0])
            } else if(value[0] === 'modelos') {
                solicitud.append(value[0], JSON.stringify(value[1]));
            } else if(value[0] === 'tipo_pieza_id') {
                solicitud.append(value[0], data.tipo_pieza.id);
            } else if(value[0] === 'comentario') {
                solicitud.append(value[0], value[1]?.length > 0 ? value[1] : null);
            } else { solicitud.append(value[0], value[1]) }
        });

        solicitud.append('tipo', offline ? 'offline' : 'reconstruidos');

        if(data.pieza.length > 0) solicitud.append('pieza', data.pieza[0]);
        else solicitud.append('pieza', null);

        onSubmitSolicitud(solicitud);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div className="w-100">
                <label>Tipo de solicitud</label>

                <div className="w-100 d-flex align-items-center justify-content-start">
                    <div className="d-flex flex-row align-items-center">
                        <Switch 
                            color="success"
                            checked={offline}
                            onChange={handleChangeOffline}
                            inputProps={{ 'aria-label': 'controlled' }} /> 
                        <label>Desguace Offline</label>
                    </div>

                    <div className="d-flex flex-row align-items-center">
                        <Switch 
                            color="success"
                            checked={reconstruidos}
                            onChange={handleChangeReconstruidos}
                            inputProps={{ 'aria-label': 'controlled' }} /> 
                        <label>Reconstruidos</label>
                    </div>
                </div>

                {(reconstruidos || offline) &&
                    <form className="form mt-4 d-flex flex-column align-items-end" onSubmit={handleSubmit(onSubmit)}>
                        <div className="w-100 d-flex flex-column flex-md-row flex-wrap flex-md-nowrap ">
                            <div className="form-group me-3">
                                <label htmlFor="telefono">Teléfono cliente</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    maxLength={50}
                                    {...register("telefono", { required: true })} 
                                    defaultValue={''} />

                                <div className="form-invalid">
                                    {(errors.telefono) && errors.telefono?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="referencia_pieza">Referencia pieza</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    maxLength={50}
                                    {...register("referencia_pieza", { required: true, maxLength: 50 })} 
                                    defaultValue={''} />

                                <div className="form-invalid">
                                    {(errors.referencia_pieza) && errors.referencia_pieza?.message}
                                </div>
                            </div>

                            <div className="form-group ms-0 ms-md-3">
                                <label htmlFor="bastidor">Bastidor del vehiculo</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    maxLength={50}
                                    {...register("bastidor", { required: true, maxLength: 50 })} 
                                    defaultValue={''} />
                                
                                <div className="form-invalid">
                                    {(errors.bastidor) && errors.bastidor?.message}
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex flex-column flex-md-row flex-wrap flex-md-nowrap align-items-end">
                            <div className="form-group me-3">
                                <input 
                                    type="hidden" 
                                    {...register("tipo_pieza_id")} 
                                    defaultValue={''} />  

                                <label>Tipo de pieza</label>
                                <VirtualizedAutocomplete 
                                    id={"tipo_pieza"}
                                    control={control}
                                    options={tipoPiezasDisponibles.sort((a, b) => b.familia_tipo_pieza_id - a.familia_tipo_pieza_id)}
                                    groupBy={"familia"}
                                    labels={['nombre']}
                                    defaultValue={null} 
                                    onChange={handleTipoPiezaChange}
                                />

                                <div className="form-invalid">
                                    {(errors.tipo_pieza_id) && errors.tipo_pieza_id?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Modelo/s</label>
                                <AutocompleteSelect 
                                    options={modelos}
                                    id={'modelos'}
                                    groupBy={"marca"}
                                    selectedValues={[]}
                                    onSetValue={handleChangeModelos}
                                />
                                <input 
                                    type="hidden" 
                                    {...register("modelos_id")} 
                                    defaultValue={''} />  

                                <div className="form-invalid">
                                    {(errors.modelos_id) && errors.modelos_id?.message}
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex flex-column flex-md-row flex-wrap flex-md-nowrap">
                            <div className="form-group">
                                <label htmlFor="ficha_tecnica">Ficha técnica</label>
                                <input 
                                    type="file" 
                                    className="form-control" 
                                    {...register("ficha_tecnica", { required: true, maxLength: 255 })} 
                                    defaultValue={''} />

                                <div className="form-invalid">
                                    {(errors.ficha_tecnica) && errors.ficha_tecnica?.message}
                                </div>
                            </div>

                            <div className="form-group ms-0 ms-md-3">
                                <label htmlFor="pieza">Foto de la pieza (opcional)</label>
                                <input 
                                    type="file" 
                                    className="form-control" 
                                    {...register("pieza", { maxLength: 255 })} 
                                    defaultValue={''} />
                            </div>
                        </div>

                        <div className="w-100 d-flex flex-column flex-md-row flex-wrap flex-md-nowrap">
                            <div className="form-group">
                                <label htmlFor="message">Comentario</label>
                                <textarea 
                                    className="form-control" 
                                    {...register("comentario", { maxLength: 255 })} 
                                    defaultValue={''} ></textarea>
                            </div>
                        </div>
                        
                        <div className="d-flex align-items-center align-self-end ms-3">
                            <button 
                                type="submit" 
                                disabled={disable}
                                className="btn btn-primary align-self-end">Guardar</button>
                        </div>
                    </form>
                }
            </div>
        </div>
    );
}