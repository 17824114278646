// LOAD ALL THE READ ONLY DATA
import { getTipoPiezas } from "../views/Dashboard/components/shared/services/tipo_piezas";
import { makeFetchingReducer, makeAsyncTypes, asyncMac, makeSetReducer } from "./utils";

const asyncTipos = makeAsyncTypes('tipo_piezas');

const [setPending, setFullFilled, setError ] = asyncMac(asyncTipos);

export const tipoPiezasFullfilledReducer = makeSetReducer(['tipo_piezas/fullfilled']);

export const fetchingReducer = makeFetchingReducer([
    'tipo_piezas/pending', 
    'tipo_piezas/fullfilled', 
    'tipo_piezas/rejected'
]);

export const fetchTipoPiezas = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getTipoPiezas()
        const data = await response.data;
        dispatch({type: 'tipo_piezas/fullfilled', payload: data});
        setFullFilled(data);
    } catch (e) {
        dispatch(setError(e.message))
    }
}