import React, { useEffect, useState } from "react";
import './lightbox.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Zoom } from 'swiper/modules';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import placeholderVideo from '../../../../../../images/placeholder_video.png';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

export default function LightBoxImages({
    images,
    startIndex,
    pieza,
    fondoReco,
    onClose
}) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    useEffect(() => {
    }, [images, startIndex, pieza]);

    return (
        <div className="lightbox">
            <div className="lightbox-container">
                <div className="lightbox-header">
                    <div className="toolbar d-flex align-items-center">
                        <span>
                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#FFF" onClick={() => onClose()} />
                        </span>
                       
                    </div>
                </div>

                <Swiper
                    style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                    }}
                    loop={true}
                    spaceBetween={10}
                    navigation={true}
                    zoom={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs, Zoom]}
                    initialSlide={startIndex}
                    className="swiperPrincipal"
                >
                    { images?.map((image, i) => {
                        return (
                            <SwiperSlide key={i}>
                            <div className={"swiper-zoom-container w-100 h-100" + (fondoReco ? ' fondo-reco' : '')}>
                                    { (image.includes('data:video/mov') || image.includes('data:video/mp4')) ?
                                        <video className="w-100 h-100" controls>
                                            <source src={image} type="video/mp4"></source>
                                            <small className="w-100 d-block text-center">Tu navegador no permite visualizar videos.</small>
                                        </video>
                                    :
                                        <img 
                                            key={pieza.id_referencia_plataforma + '-' + i} 
                                            src={image} 
                                            alt={pieza.referencia_pieza}
                                        />
                                    }
                                    
                                </div>
                            </SwiperSlide>
                        )
                    }) }
                </Swiper>

                <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiperThumbs"
                >
                    { images?.map((image, i) => {
                        return (
                            <SwiperSlide key={'thumb-' + i}>
                                <img 
                                    key={pieza.id_referencia_plataforma + '-' + i} 
                                    src={(image.includes('data:video/mov') || image.includes('data:video/mp4')) ? placeholderVideo : image} 
                                    alt={pieza.referencia_pieza}
                                />
                            </SwiperSlide>
                        )
                    }) }
                </Swiper>
            </div>
        </div>
    );
}