import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function PorcentajeForm({
    porcentaje, 
    userId,
    onSubmitItem
}) {
    let formSimpleSchema = yup.object({
        porcentaje: yup.number().required("El porcentaje es obligatorio"),
      }).required();

    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        resolver: yupResolver(formSimpleSchema),
        mode: "onChange",
        defaultValues: {
            id: userId,
            porcentaje: porcentaje ? porcentaje : 0
        }
    });

    useEffect(() => {
        reset({porcentaje: porcentaje ? porcentaje : 0});
    }, [porcentaje]);


    const handleSubmitPorcentaje = async(data, e) => {
        e.preventDefault();
        data.porcentaje_pieza_no_encontrada = data.porcentaje;
        delete data.porcentaje;
        onSubmitItem(data);
    }

    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitPorcentaje)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={userId} />

                <div className="w-100 d-flex flex-column">
                    <div className="form-group me-3">
                        <label htmlFor="porcentaje">Porcentaje</label>
                        <input 
                            type="number" 
                            step="0.01"
                            className="form-control" 
                            min={0}
                            {...register("porcentaje", { required: true })} 
                            defaultValue={porcentaje ? porcentaje : 0} />

                        <div className="form-invalid">
                            {(errors.porcentaje) && errors.porcentaje?.message}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}