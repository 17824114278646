import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchGeneralMetrics, fetchUserMetrics } from '../../../../../../../../redux/features/dashboard';
import '../../../Dashboard.scss';
import DateSelector from '../../../../../shared/components/DateSelector';
import UserMetrics from './components/UserMetrics';
import GeneralMetrics from './components/GeneralMetrics';
import ShowEcommerceBtn from '../ShowEcommerceBtn';
import RecambistaResume from './components/RecambistaResume';
import InfoGeneralRecambistas from '../shared/InfoGeneralRecambistas';

export default function UsersTab() {
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = useState(null);
    const [startDate, setStartDate] = useState(moment().date() >= 26 ?
        moment().date(26).format('YYYY-MM-DD')
    :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().date() >= 26 ? 
        moment().add(1, 'month').date(25).format('YYYY-MM-DD')
    :   moment().date(25).format('YYYY-MM-DD'));
    const [view, setView] = useState('variable');
    const [currentRecambistas, setCurrentRecambistas] = useState(null);
    const [showEcommerce, setShowEcommerce] = useState(false)
    const dashboard = useSelector(state => {
        return state.dashboard.entity.admin
    });
    const recambistas = useSelector(state => {
        return state.recambistas
    });

    useEffect(() => {
        if(dashboard.generalMetrics) {
            const recambistasLabels = dashboard.generalMetrics.num_presupuestos_chart.datasets.map(d => {
                return d.label;
            })

            const recambistasAux = recambistas.reduce((acc, curr) => {
                if(recambistasLabels.find(r => r === curr.username.toUpperCase())) {
                    acc.push(curr);
                    return acc;
                }
                return acc;
            }, [])

            setCurrentRecambistas(recambistasAux);
        } else dispatch(fetchGeneralMetrics(startDate, endDate, view));
    }, [dashboard]);


    const handleChangeUser = (e) => {
        if(parseInt(e.target.value) === -1) {
            dispatch(fetchGeneralMetrics(startDate, endDate, view));
            setCurrentUser(null);
        } else {
            dispatch(fetchUserMetrics(e.target.value, startDate, endDate, view));
            setCurrentUser(e.target.value);
        }
    }

    const handleDateInterval = (startDate, endDate, view) => {
        setView(view);
        setStartDate(startDate);
        setEndDate(endDate);

        if(!currentUser) dispatch(fetchGeneralMetrics(startDate, endDate, view));
        else dispatch(fetchUserMetrics(currentUser, startDate, endDate, view));
    }

    useEffect(() => {
      if (showEcommerce) {
        dispatch(fetchUserMetrics(45, startDate, endDate, view));
        return setCurrentUser(45);
      }
      setCurrentUser(null)
    }, [showEcommerce])
    

    return (
        <div className='w-100 d-flex flex-column align-items-center dashboard'>
            <InfoGeneralRecambistas
                data={dashboard?.general}
            />
            
            <div className='w-100 mt-4 summary'>
                <h1>Información por usuario</h1>

                <div className='w-100 d-flex align-items-center position-relative pt-5 mb-4'>
                    <ShowEcommerceBtn setShowEcommerce={setShowEcommerce} />

                    { showEcommerce
                        ? <select id="responsable">
                            <option value='ecommerce'>Ecommerce</option>
                           </select>
                        : (currentRecambistas && currentRecambistas.length > 0) &&
                            <select 
                                id="responsable"
                                defaultValue={currentUser ? currentUser : -1}
                                onChange={(e) => handleChangeUser(e)}>
                                <option key={'recambistas-' + 0} value={-1}>Todos</option>
                                {currentRecambistas.map(op => <option key={'recambistas-' + op.id} value={op.id}>{op.username}</option>)}
                            </select>
                    }

                    <DateSelector onSelectedDate={handleDateInterval} /> 
                </div>

                { (dashboard.generalMetrics?.resumen && dashboard.generalMetrics?.resumen?.length > 0) &&
                    <RecambistaResume 
                        startDate={startDate}
                        endDate={endDate}
                        resumen={dashboard.generalMetrics?.resumen}
                    />
                }

                { (!currentUser && dashboard.generalMetrics) &&  
                    <GeneralMetrics 
                        view={view}
                        data={dashboard.generalMetrics} />
                }

                { (currentUser && dashboard.userMetrics) && 
                    <UserMetrics 
                        view={view}
                        currentUser={dashboard.userMetrics} />
                }
            </div>
        </div>
    )
}
